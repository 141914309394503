<template>
  <div class="button-group-input">
    <input
      type="text"
      v-model="FileName"
      placeholder="Selecione um arquivo..."
      class="w-full sm:w-7/12 mb-2 float-left"
      disabled
    />
    <label :class="['button button-input text-white', buttonBackground]">
          <input type="file" :accept="accept" style="display: none;" @change='fileChange' ref="fileSelected">
      {{ buttonText }}
    </label>

    <label class="button button-input button-rounded bg-amber-500">
        <span  @click="removerArquivo">
          Remover
        </span>
    </label>
  </div>
</template>
<script>
export default {
  name: 'uploadArquivos',
  props: ['accept', 'index'],
  data () {
    return {
      File: '',
      FileName: '',
      buttonBackground: "bg-lime-600",
      buttonText: "Selecionar",
    }
  },
  methods: {
    fileChange (e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return

      this.File = files[0]
      this.FileName = files[0].name

      // Tratamento para o reaproveitamento do componente
      if (typeof this.index !== 'undefined' && this.index !== null) {
        this.$emit('click-salvar', this.File, this.index)
      } else {
        this.$emit('click-salvar', this.File)
      }
    },
    clearValues () {
      this.$refs.fileSelected.value = ''
      this.File = ''
      this.FileName = ''
    },
    removerArquivo () {
      this.$emit('click-remover', this.index)
    }
  },
  mounted () {
    this.clearValues()
  }
}
</script>
<style scoped>
  hr.green {
    border-top: 1px solid mediumseagreen;
  }
</style>
