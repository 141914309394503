<template>
<Layout>
  <div class="form-heading text-center mb-6">
    <div
      class="bg-lime-600 bg-opacity-30 text-lime-800 rounded-lg p-5"
    >
      Aguarde a exibição da mensagem de sucesso e a exibição do número de protocolo para dar como concluído.
    </div>
  </div>

  <form @submit.prevent="submit">
    <div v-if="showSelecionarAssunto" class="md:grid md:grid-cols-3 md:gap-6 mb-6 sm:mb-0">
      <div class="md:col-span-1">
        <div class="form-heading border-none">
          <h6 class="text-left">Assunto da Petição</h6>
          <p class="text-sm">
            Campos marcados com (<span class="text-red-700">*</span>) são de
            preenchimento obrigatório.
          </p>
        </div>
      </div>

      <div class="section-content md:col-span-2">
        <div class="form">
          <div class="body">
            <div :class="{ group: true, groupError: $v.assunto.$error }">
              <label class="label"
              >Assunto <span class="text-red-700">*</span></label
              >

              <select
                class="w-full sm:w-6/12"
                v-model.trim="$v.assunto.$model"
              >
                <option :value="[]" disabled selected>
                  Selecione um assunto
                </option>

                <option
                  v-for="(assuntoItem, index) in assuntoLista"
                  :key="index"
                  :value="assuntoItem.nome"
                >
                  {{ assuntoItem.nome }}
                </option>
              </select>

              <p class="messageError" v-if="!$v.assunto.validSelected">
                Assunto deve ser selecionado.
              </p>
            </div>

            <div v-if="candidatos.length > 0" :class="{ group: true, groupError: $v.assunto.$error }">
              <label class="label"
              >Candidato <span class="text-red-700">*</span></label
              >

              <select
                class="w-full sm:w-6/12"
                v-model.trim="$v.candidato.$model"
              >
                <option :value="{}" disabled selected>
                  Selecione um candidato
                </option>

                <option
                  v-for="(item, index) in candidatos"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.responsavelNome }}
                </option>
              </select>

              <p class="messageError" v-if="!$v.candidato.validSelected">
                Candidato deve ser selecionado.
              </p>
            </div>


          </div>
        </div>
      </div>
    </div>
    <div class="py-5">
      <div class="border-t border-gray-200"></div>
    </div>
    <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
        <div class="form-heading border-none">
          <h6 class="text-left">{{ labelResponsavelInscricao }}</h6>
          <p class="text-sm">
            Campos marcados com (<span class="text-red-700">*</span>) são de
            preenchimento obrigatório.
          </p>
        </div>
      </div>
      <div  class="section-content md:col-span-2">
        <div class="form">
          <div class="body">

            <div :class="{ group: true, groupError: $v.cpf.$error }">
              <label class="label" v-html="cpfLabel">
              </label>

              <input
                type="text"
                class="w-full sm:w-4/12"
                :placeholder="cpfPlaceholder"
                v-model.trim="$v.cpf.$model"
              />

              <p class="messageError" v-if="!$v.cpf.required">
                {{cpfFieldName}} deve ser preenchido.
              </p>

              <p class="messageError" v-if="!$v.cpf.numeric">
                {{cpfFieldName}} deve conter apenas números.
              </p>
            </div>

            <div :class="{ group: true, groupError: $v.nome.$error }">
              <label class="label"
              >Nome completo <span class="text-red-700">*</span></label
              >

              <input
                type="text"
                class="w-full sm:w-7/12"
                placeholder="Nome completo"
                v-model.trim="$v.nome.$model"
              />

              <p class="messageError" v-if="!$v.nome.required">
                Nome completo deve ser preenchido.
              </p>

              <p class="messageError" v-if="!$v.nome.minLength">
                Nome completo deve ter pelo menos
                {{ $v.nome.$params.minLength.min }} letras.
              </p>
            </div>

            <div :class="{ group: true, groupError: $v.email.$error }">
              <label class="label">
                E-mail <span class="text-red-700">*</span></label
              >

              <input
                type="text"
                class="w-full sm:w-6/12"
                placeholder="E-mail"
                v-model.trim="$v.email.$model"
              />

              <p class="messageError" v-if="!$v.email.required">
                E-mail deve ser preenchido.
              </p>

              <p class="messageError" v-if="!$v.email.email">
                E-mail informado deve ser válido.
              </p>
            </div>

            <div :class="{ group: true, groupError: $v.celular.$error }">
              <label class="label"
              >Celular</label
              >

              <input
                type="text"
                class="w-full sm:w-7/12"
                placeholder="Celular"
                v-model.trim="$v.celular.$model"
              />

              <p class="messageError" v-if="!$v.celular.required">
                 Celular deve ser preenchido.
              </p>

              <p class="messageError" v-if="!$v.celular.minLength">
                Celular deve ter pelo menos
                {{ $v.nome.$params.minLength.min }}.
              </p>
            </div>

            <div class="group">
              <label class="label">
                Observação
              </label>

              <textarea
                class="w-full sm:w-8/12"
                :placeholder="
                    'Observação...'
                  "
                :maxlength="9999"
                rows="4"
                v-model="observacao"
              ></textarea>
            </div>
            <div class="group">

              <button type="button" class="button button-rounded bg-blue-400 text-white" @click="adicionarUploadArquivo">
                Adicionar arquivos
              </button>

              <label class="label">
                Arquivos comprovação da petição <span
                class="text-red-700"
              >*</span>
              </label>
              <div v-for="(uploadarquivo, k) in uploadArquivosArray" :key="k">
                <upload-arquivos :class="{ 'invisible': !uploadarquivo.display }"
                                 @click-salvar="adicionarArquivo"
                                 @click-remover="removerUploadArquivo"
                                 :accept="'application/pdf'"
                                 :index="uploadarquivo.index" />
              </div>
            </div>
            <div class="group">
              <button type="submit"
                      class="button button-rounded bg-blue-500 text-white">
                ENVIAR PETIÇÃO
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

<!--  <PreLoad :loading="loading" />-->
</Layout>
</template>

<script>
import Layout from "../../components/layout/petition/Layout.vue";
import { mapActions, mapGetters } from "vuex";
import UploadArquivos from '../../components/uploadArquivos.vue'
import md5 from 'md5'
import api from "../../api";
import {
  required,
  minLength,
  numeric,
  email,
} from "vuelidate/lib/validators";

function clientName () {
  let hostname = window.location.hostname
  let result = hostname
  if (hostname.startsWith('www')) {
    result = hostname.split('.')[1]
  } else {
    result = hostname.split('.')[0]
  }
  return result
}

function extname (filename) {
  let parts = filename.split('.')
  return '.' + parts[parts.length - 1]
}

export default {
  name: "PetitionRegister",
  components: {
    Layout,
    UploadArquivos
  },
  data () {
    return {
      showSelecionarAssunto: true,
      candidatos: [],
      assuntoLista: [{nome:'IMPUGNAÇÃO INSCRIÇÃO'},{nome:'IMPUGNAÇÃO CAMPANHA ELEITORAL'},{nome: 'CONTESTAÇÃO IMPUGNAÇÃO'},{nome: 'CONTESTAÇÃO CAMPANHA ELEITORAL' }],
      uploadArquivosArray: [
        {file: null, index: 0, display: true}
      ],
      assunto: '',
      idcargo: '',
      protocolo: '',
      senha: '',
      chapa: {},
      candidato: {},
      registro: '',
      nome: '',
      celular: '',
      cpf: '',
      email: '',
      contato: '',
      documentos: {},
      loading: false,
      erro: false,
      msgErro: [],
      observacao: ''
    }
  },
  computed: {
    ...mapGetters("eleicaonet", ["getParamHotsite"]),
    labelResponsavelInscricao() {
      if (
        this.getParamHotsite.pages.petition &&
        this.getParamHotsite.pages.petition.register &&
        this.getParamHotsite.pages.petition.register.labelResponsavelInscricao
      ) {
        return this.getParamHotsite.pages.petition.register.labelResponsavelInscricao
      } else {
        return `Requerente`
      }
    },
    cpfLabel () {
      if (
        this.getParamHotsite.pages.petition &&
        this.getParamHotsite.pages.petition.register &&
        this.getParamHotsite.pages.petition.register.labels &&
        this.getParamHotsite.pages.petition.register.labels.cpf
      ) {
        return  this.getParamHotsite.pages.petition.register.labels.cpf
      } else {
        return  "CPF<span class=\"text-red-700\">*</span>"
      }
    },
    cpfPlaceholder () {
      if (
        this.getParamHotsite.pages.petition &&
        this.getParamHotsite.pages.petition.register &&
        this.getParamHotsite.pages.petition.register.placeholder &&
        this.getParamHotsite.pages.petition.register.placeholder.cpf
      ) {
        return  this.getParamHotsite.pages.petition.register.placeholder.cpf
      } else {
        return "Apenas números"
      }

    },
    cpfFieldName () {
      if (
        this.getParamHotsite.pages.petition &&
        this.getParamHotsite.pages.petition.register &&
        this.getParamHotsite.pages.petition.register.fieldname &&
        this.getParamHotsite.pages.petition.register.fieldname.cpf
      ) {
        return  this.getParamHotsite.pages.petition.register.fieldname.cpf
      } else {
        return "CPF"
      }
    }
  },
  validations () {
    return {
      assunto: { required, minLength: minLength(5)},
      nome: {required, minLength: minLength(8)},
      email: {required,
        email,},
      celular: {minLength: minLength(11)},
      cpf: {required, numeric},
      candidato: {required}
    }
  },
  methods: {
    ...mapActions(["changeLoading", "sendNotification"]),
    loadData () {
      let vm = this
      let data
      api.request('get', '/Chapas/' + this.idcargo)
        .then(values => {
          data = values.data
          data.participantes = JSON.parse(data.participantes)
          vm.chapa = data
        })
    },
    async submit () {
      this.changeLoading(true);
      this.sendNotification({ type: "info", message: 'Aguarde: Sua petição esta sendo processada !'})
      this.$v.$touch();
      // this.hideMsgErro()

      let msgErro = this.validarDadosPreenchidos()
      if (msgErro.length > 0) {
        this.changeLoading(false);
        this.sendNotification({ type: "error", message: msgErro.join('<br>')})
        return
      }

      let peticao = await this.criarPeticao()

      if (peticao.status === 'falhou') {
        console.log(peticao.data.msg)
        this.changeLoading(false);
        this.sendNotification({ type: "error", message: peticao.data.msg})
        return
      }

      this.senha = window.btoa(peticao.data.senha)
      this.protocolo = peticao.data.protocolo

      let arquivosEnviados = await this.processarEnvioArquivos()
      console.log('Arquivos enviados')
      console.log(arquivosEnviados)
      peticao = await this.atualizarPeticao(arquivosEnviados)
      console.log('Petição atualizada')

      this.changeLoading(false);
      this.sendNotification({ type: "success", message: 'Petição registrada com êxito!'})
      this.$router.push({ name: 'PetitionSituation', params:{peticao} })
      return
    },
    criarPeticao () {
      let peticao = {}


      let candidato = this.candidatos.find((candidato) => {
        return this.candidato == candidato.id
      })

      peticao.impugnadoNome = candidato ? candidato.responsavelNome : null;
      peticao.inscricaoid = candidato ? candidato.id : null;
      peticao.assunto = this.assunto
      peticao.observacao = this.observacao

      peticao.protocolo = this.protocolo
      peticao.responsavelRegistro = this.registro
      peticao.responsavelNome = this.nome
      peticao.responsavelCpf = this.cpf
      peticao.responsavelEmail = this.email
      peticao.responsavelCelular = this.contato

      return new Promise((resolve, reject) => {
        api.post('/peticao/novo', { peticao })
          .then((value) => {
            resolve(value.data)
          })
          .catch((reason) => {
            console.error(reason)
            this.loading = false
            reject(reject)
          })
      })
    },
    async processarEnvioArquivos () {
      let result = []
      let upload
      for (let i = 0; i < this.uploadArquivosArray.length; i++) {
        if (this.uploadArquivosArray[i].display === true && this.uploadArquivosArray[i].file != null) {
          upload = this.uploadArquivo(this.uploadArquivosArray[i].file)
          result.push(upload)
        }
      }
      return Promise.all(result)
    },
    uploadArquivo (file) {
      let formData = new window.FormData()
      let filename
      let nomeCliente = clientName()

      filename = nomeCliente + "-" + file.name;
      nomeCliente = nomeCliente + '/documentos'

      let extension = extname(file.name)
      filename = md5(filename + (new Date().getTime()))
      formData.append('file', file, filename + extension)

      let url = '/peticao/upload-documento'
      url = `${url}?protocolo=${this.protocolo}&senha=${this.senha}&filepath=${nomeCliente}&filename=${filename}`

      let request = api.post( url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      request.then((response) => {
        console.log('SUCCESS!! ', response.data)
      }).catch((reason) => {
        this.loading = false
        console.log('FAILURE!! ', reason)
      })

      return request
    },
    atualizarPeticao (arquivosEnviados) {
      let documentos = []

      for (let index in arquivosEnviados) {
        let arquivo = arquivosEnviados[index].data
        documentos.push({ filename: arquivo.filename, url: arquivo.Location })
      }

      return new Promise((resolve, reject) => {
        api.post('/peticao/atualizarDocumentos', { protocolo: this.protocolo, senha: this.senha, documentos: documentos })
          .then((value) => {
            resolve(value.data)
          })
          .catch((reason) => {
            console.error(reason)
            this.loading = false
            reject(reject)
          })
      })
    },
    adicionarArquivo (file, index) {
      let b = this.uploadArquivosArray.find(a => a.index === index)
      b.file = file
    },
    adicionarUploadArquivo () {
      this.uploadArquivosArray.push({file: null, index: this.uploadArquivosArray.length, display: true})
    },
    removerUploadArquivo (index) {
      console.log('remover ', index)
      this.uploadArquivosArray.find(u => u.index === index).display = false
    },
    validarDadosPreenchidos () {
      let msgErro = []

      if (this.candidato === '') {
        msgErro.push('Você deve selecionar qual o candidato que deseja contestar')
      }

      if (!this.nome || this.nome < 3) {
        msgErro.push('Falta informar o nome do requerente corretamente')
      }

      if (!this.email || !this.ehValidoEmail(this.email)) {
        msgErro.push('Falta informar o e-mail do requerente corretamente')
      }

      // if (!this.contato || this.contato.length < 10) {
      //   msgErro.push('Falta informar o Nº para contato do requerente corretamente')
      // }

      let msgCpf = this.validarCpf(this.cpf)
      if (msgCpf !== '') {
        msgErro.push(msgCpf)
      }

      let validaSelecao = true
      for (let arquivo in this.uploadArquivosArray) {
        if (this.uploadArquivosArray && this.uploadArquivosArray[arquivo].file != null) {
          validaSelecao = false
          break
        }
      }
      if (validaSelecao) {
        msgErro.push('Você deve selecionar ao menos 1 arquivo de comprovação para seu contestamento')
      }

      return msgErro
    },
    ehValidoEmail (email) {
      return (email.length > 3 && email.indexOf('@') > 0)
    },
    validarCpf (cpf) {
      if (!cpf) {
        return 'Falta informar o cpf do requerente'
      }

      // if (!libraryCPF.isValid(cpf)) {
      //   return 'CPF do contestante inválido - precisa ter 11 digitos válidos.'
      // }
      return ''
    },
    validarCelular (celular) {
      if (!celular) {
        return 'Falta informar o celular'
      }
      let n = celular.replace(/\D/g, '')
      if (n.length !== 11) {
        return 'Celular incorreto - precisa ter 11 digitos'
      }
      return ''
    },
    hideMsgErro () {
      this.erro = false
      this.msgErro = []
    }
  },
  async mounted() {
    let response = await api.get('peticao/info')
    let candidatos = response.data
    candidatos.forEach ((candidato) => {
      let {id , responsavelNome} = candidato
      this.candidatos.push({id, responsavelNome})
    })
    console.log('candidatos ', this.candidatos)
    if (this.getParamHotsite.pages.petition.subjects) {
      this.assuntoLista = this.getParamHotsite.pages.petition.subjects
    }
  }
};
</script>

<style scoped>

</style>

